import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import EmailAccountRegistrationController from "./EmailAccountRegistrationController";
import Header from "../../../components/src/Header.web";
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  // Customizable Area Start
  signUpSchema = () => {
    return Yup.object().shape({
      email: Yup.string().required("*Email is required")
        .email("*Enter valid email"),
      password: Yup.string()
        .min(8, '*Password must be at least 8 characters long')
        .matches(/[a-z]/, '*Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, '*Password must contain at least one uppercase letter')
        .matches(/\d/, '*Password must contain at least one digit')
        .matches(/[@$!%*?&#]/, '*Password must contain at least one special character')
        .required('*Password is required')
    });
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        <Header navigation={this.props.navigation}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box className="form">
              <Box textAlign="center">
                {!this.props.isAdmin && <img src="/builderLogo.svg" alt="logo" className="logo" />}
                <div className="title">Sign in to join <b>Thinkspike</b></div>
              </Box>
              <Formik
                data-test-id="formik"
                initialValues={{
                  email: this.state.email,
                  password: this.state.password
                }}
                validationSchema={this.signUpSchema}
                onSubmit={(values) => {
                  this.signIn();
                }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="form"
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Box mb="20px">
                          <label>Email</label>
                          <CustomInput
                            fullWidth
                            data-test-id="email"
                            placeholder="Email address"
                            value={this.state.email}
                            onChange={(event) => {
                              setFieldValue("email", event.target.value.trim());
                              this.setState({ email: event.target.value.trim() })
                            }}
                          />
                          {touched.email && errors.email &&
                            <div className="text-danger">{errors.email}</div>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb="20px">
                          <label>Password</label>
                          <CustomInput
                            fullWidth
                            data-test-id="password"
                            placeholder="Enter password"
                            type={this.state.isShowPassword ? "text" : "password"}
                            value={this.state.password}
                            onChange={(event) => {
                              setFieldValue("password", event.target.value.trim());
                              this.setState({ password: event.target.value.trim() })
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="visibility"
                                  onClick={() => {
                                    this.onVisibilityChange();
                                  }}
                                  edge="end"
                                  style={{ color: "#A2A2A2" }}
                                >
                                  {this.state.isShowPassword ? (
                                    <VisibilityOutlinedIcon />
                                  ) : (
                                    <VisibilityOffOutlinedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {touched.password && errors.password &&
                            <div className="text-danger">{errors.password}</div>
                          }
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb="20px" display="flex" justifyContent="space-between">
                          <Box
                            data-test-id="checkbox"
                            className="remember-box"
                            onClick={() => {
                              this.onCheckboxChange();
                            }}
                          >
                            <Checkbox
                              data-test-id="checkbox-icon"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  this.onCheckboxChange();
                                }
                              }}
                              sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "4px",
                                color: "black",
                                marginRight: "8px",
                                padding: "0px",
                                '&.Mui-checked': {
                                  color: "black"
                                },
                              }}
                              icon={
                                <Box
                                  border="1px solid #8b7878"
                                  borderRadius="4px"
                                  height="18px"
                                  width="18px"
                                >
                                  <div />
                                </Box>
                              }
                              checkedIcon={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="20" height="20" rx="4" fill="#1C1C1C" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
                                </svg>
                              }
                              checked={this.state.isRemember}
                            />
                            <Box className="remember">Remember me</Box>
                          </Box>
                          <ForgotButton
                            data-test-id="forgot-password"
                            onClick={this.onForgotPasswordPage}
                          >
                            Forgot password?
                          </ForgotButton>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomButton
                          fullWidth
                          disabled={this.state.loading}
                          data-test-id="button"
                          sx={{ marginTop: "10px", height: "45px" }}
                          type="submit"
                        >
                          {this.state.loading && <CircularProgress size={15} sx={{ color: "#fff", marginRight: "10px", marginBottom: "2px" }} />}
                          Sign In
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Header>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  "& .form": {
    margin: "20px 0px",
    maxWidth: "440px",
    marginBottom: "14px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      padding: "0px 10px"
    },
    "& .logo": {
      textAlign: "center"
    },
    "& .title": {
      fontFamily: 'Heebo-Light',
      margin: "17px 0px 35px",
      fontSize: "24px",
      color: "181818",
      "& span": {
        fontFamily: 'Heebo-Light'
      }
    },
    "& label": {
      fontFamily: "Heebo-Medium",
      color: "#9D9D9D"
    },
    "& .remember-box": {
      display: "flex",
      cursor: "pointer",
    },
    "& .remember": {
      fontSize: "14px",
      color: "#9D9D9D"
    },
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #9D9D9D",
  backgroundColor: "#fff",
  borderRadius: "4px",
  marginTop: "3px",
  "& input": {
    padding: "11px 14px",
    borderRadius: "4px",
  },
  "& button": {
    marginRight: "4px",
  }
});

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  fontSize: "16px !important",
  padding: "6px 35px !important",
  backgroundColor: "#1C1C1C",
  color: "white !important",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(83 78 78)",
  }
}));

const ForgotButton = styled(Button)({
  padding: "0px",
  fontFamily: "Heebo-Regular",
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#141414 !important",
  fontSize: "14px !important",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#fff",
  }
});
// Customizable Area End