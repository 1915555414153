import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

interface IProps {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: {};
  type?: string;
  token?: string | null;
}

interface IHeader {
  "Content-Type"?: string | null;
  "token"?: string | null;
}

export const apiCall = async (data: IProps) => {
  const { contentType, method, endPoint, body, type, token } = data;
  const header: IHeader = {};
  if (contentType) {
    header['Content-Type'] = contentType;
  }
  if (token) {
    header['token'] = token;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  Object.keys(header).length !== 0 && requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  body && type !== 'formData'
    ? requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}