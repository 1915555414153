import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import EmailAccountRegistration from "./EmailAccountRegistration.web";

interface Props { navigation: any; }
// Customizable Area End

export default class AdminLogin extends BlockComponent<Props, {}, {}> {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <EmailAccountRegistration
          id=""
          isAdmin={true}
          navigation={this.props.navigation}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End