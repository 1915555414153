import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import ResetPassword from "./ResetPassword.web";

interface Props { navigation: any; }
// Customizable Area End

export default class AdminResetPassword extends BlockComponent<Props, {}, {}> {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ResetPassword
          isAdmin={true}
          navigation={this.props.navigation}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End