import React, { ReactNode } from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
  Box, Button, Typography, Drawer, List, ListItem, ListItemText, ListItemAvatar
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from "@mui/material/styles";
import Contactus from "../../blocks/contactus/src/ContactPage.web";
import { toast } from "react-toastify";

interface Props {
  navigation: any;
  issupportpage?: boolean;
  islogin?: boolean;
  children: ReactNode;
}

interface S {
  loading: boolean;
  isOpen: boolean;
}

interface SS {
  navigation: string;
}

interface IWrapper {
  islogin: boolean | undefined;
  issupportpage: boolean | undefined;
}

export default class Header extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isOpen: false
    };
  }

  contactPage = () => {
    this.setState({ isOpen: true });
  }

  onClose = () => {
    this.setState({ isOpen: false });
  }

  privacyPage = () => {
    toast.info("Coming soon!");
  }

  termPage = () => {
    toast.info("Coming soon!");
  }

  render() {
    const islogin = this.props.islogin;
    return (
      <Wrapper
        islogin={islogin}
        issupportpage={this.props.issupportpage}
      >
        {this.state.isOpen &&
          <Contactus
            id=""
            isUserLogin={false}
            navigation={this.props.navigation}
            onClose={this.onClose}
          />
        }
        <header>
          <Box>
            <img src="/logo.svg" alt="logo" className="logo" />
          </Box>
          {islogin &&
            <Box className="parent">
              <Box>
                <HeaderButton isActive>Dashboard</HeaderButton>
                <HeaderButton>My Learning</HeaderButton>
                <HeaderButton>Support</HeaderButton>
              </Box>
            </Box>
          }
          <Box>
            {!islogin && <CustomButton onClick={this.contactPage}>Contact us</CustomButton>}
            {islogin &&
              <Box display="flex" alignItems="center">
                <img src="/builderLogo.svg" alt="logo" className="builder-logo" />
                <Box className="user-name">
                  <p>Damodar Nagina</p>
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
            }
          </Box>
        </header>
        <main>
          {this.props.issupportpage
            ? <Box display='flex'>
              <Drawer
                className="sidebar-drawer"
                sx={{
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: 314,
                    marginTop: "70px",
                    borderRight: "none",
                    boxSizing: 'border-box',
                  },
                }}
                variant="permanent"
                anchor="left"
              >
                <Box p="24px 10px">
                  <Typography className="title">Support</Typography>
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4ZM10 4C10 2.9 9.1 2 8 2C6.9 2 6 2.9 6 4C6 5.1 6.9 6 8 6C9.1 6 10 5.1 10 4ZM8 9C5.33 9 0 10.34 0 13V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V13C16 10.34 10.67 9 8 9ZM2 13.01V14H14V13C13.8 12.29 10.7 11 8 11C5.3 11 2.2 12.29 2 13.01Z" fill="black" />
                        </svg>
                      </ListItemAvatar>
                      <ListItemText primary="Account" secondary="Settings related to your personal information and account credentials" />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M1 0H17C17.55 0 18 0.45 18 1V1.01C18 1.56 17.55 2.01 17 2.01H1C0.45 2.01 0 1.56 0 1.01V1C0 0.45 0.45 0 1 0ZM17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5ZM1 12H11C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12Z" fill="black" />
                        </svg>
                      </ListItemAvatar>
                      <ListItemText primary="Help Center" secondary="View and manage FAQ's" />
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
              <Box ml="314px" width="100%">
                {this.props.children}
              </Box>
            </Box>
            :
            <>{this.props.children}</>
          }
        </main>
        <footer>
          <Box>
            <p className="first" onClick={this.termPage}>Terms & Condition</p>
            <p onClick={this.privacyPage}>Privacy Policy</p>
          </Box>
          <Box className="rights">© {new Date().getFullYear()} Thinkspike. All Rights Reserved</Box>
        </footer>
      </Wrapper>
    );
  }
}

// Customizable Area Start

const Wrapper = styled(Box)<IWrapper>(({ theme, islogin, issupportpage }) => ({
  flexDirection: "column",
  display: "flex",
  minHeight: "100vh",
  "& header": {
    top: "0px",
    height: "70px",
    display: "flex",
    zIndex: "99",
    minHeight: "70px",
    position: "sticky",
    alignItems: "center",
    justifyContent: "space-between",
    padding: islogin ? "0px 30px" : "0px 80px",
    backgroundColor: issupportpage ? "#FAFAFA" : "#fff",
    borderBottom: issupportpage ? "none" : `1px solid ${islogin ? "#E7E5E4" : "#D6D3D1"}`,
    [theme.breakpoints.down('sm')]: {
      padding: "0px 20px",
      "& .logo": {
        height: "20px"
      }
    },
    "& .builder-logo": {
      height: "18px",
      marginRight: "15px"
    },
    "& .parent": {
      [theme.breakpoints.down('md')]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }
    },
  },
  "& .user-name": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Heebo-Regular",
    "& p": {
      margin: "0px",
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        width: '70px',
      },
    }
  },
  "& main": {
    flex: 1,
    "& .sidebar-drawer": {
      "& .title": {
        marginLeft: "10px",
        fontSize: "20px",
        fontFamily: "Heebo-Bold",
        color: "#64748B"
      },
      "& .MuiListItem-root": {
        cursor: "pointer",
        margin: "3px 0px",
        padding: "10px",
        marginBottom: "10px",
        "& svg": {
          marginRight: "15px"
        },
        "&:hover": {
          background: "#F5F5F4",
          borderRadius: "8px"
        }
      },
      "& .MuiListItemAvatar-root": {
        minWidth: "20px",
        marginTop: "11px",
      },
      "& .MuiListItemText-primary": {
        fontSize: "18px",
        color: "#0F172A",
        marginBottom: "8px",
        fontFamily: "Heebo-Bold",
      },
      "& .MuiListItemText-secondary": {
        fontSize: "14px",
        fontFamily: "Heebo-Regular",
        color: "#334155"
      },
    }
  },
  "& footer": {
    color: "#fff",
    zIndex: "999999",
    padding: "20px 52px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1C1C1C",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 20px",
      flexDirection: "column",
    },
    "& p": {
      display: "inline",
      margin: "0px",
      cursor: "pointer",
      fontSize: "14px",
    },
    "& .first": {
      marginRight: "30px"
    },
    "& .rights": {
      fontSize: "16px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "15px",
        textAlign: "center",
      },
    }
  }
}));

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  color: "white !important",
  fontSize: "16px !important",
  padding: "6px 35px !important",
  backgroundColor: "#1C1C1C",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(83 78 78)",
  }
}));

const HeaderButton = styled(CustomButton)<{ isActive?: boolean; }>(({ theme, isActive }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  color: `${isActive ? "while" : "black"} !important`,
  fontSize: "16px !important",
  padding: "6px 16px !important",
  backgroundColor: `${isActive ? "#1C1C1C" : "white"} !important`,
  borderRadius: "4px !important",
  margin: "0px 5px",
  whiteSpace: "noWrap",
  "&:hover": {
    color: "white !important",
    backgroundColor: "#1C1C1C !important",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
}));
// Customizable Area End