import React from "react";
import { Modal, Fade, CircularProgress } from "@mui/material";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return props.loading ? (
    <Modal
      style={webStyles.root}
      open={props.loading}
      closeAfterTransition
      disableAutoFocus={true}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }
      }}
    >
      <Fade in={props.loading} style={{ border: "none" }}>
        <div style={webStyles.circularContainer}>
          <CircularProgress size={40} sx={{ color: "black" }} />
        </div>
      </Fade>
    </Modal>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularContainer: {
    backgroundColor: "transparent",
    outline: "none",
    borderRadius: 29,
    width: "auto",
    height: "auto",
  }
};