//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface LessonItem {
  id: number,
  title: string
}

export interface LessonCreationBar {
  title: string,
  isActive: boolean,
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  desciption: string,
  lessonCreationBar: LessonCreationBar
  lessonList: LessonItem[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description: "",
      lessonCreationBar: {title: "", isActive: true},
      lessonList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson1) {
        this.setState({
           userDataList: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson1);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addDataCall !== null &&
      this.addDataCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse2 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson2) {
        Alert.alert(configJSON.success)
        this.setState({
          loading: false,
          showModel:false
        });
        this.getUserDataListApi()
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson2);
      }
      this.parseApiCatchErrorResponse(errorResponse2);
    }

    
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.lessonList.length != this.state.lessonList.length) {
      if (this.state.lessonList.length === 0) {
        this.setState({...this.state, lessonCreationBar: {title: "", isActive: true}})
      }
    }
  }
  addNewLesson = (title: string) => {
    const id = this.state.lessonList.length
    this.setState({...this.state, lessonCreationBar: {title: "", isActive: false}, lessonList: [...this.state.lessonList, {title, id}]});
  }

  deleteLesson = (id: number) => {
    this.setState({...this.state, lessonList: [...this.state.lessonList.filter((lesson) => lesson.id != id )]})
  }

  setCreationBarActive = () => {
    this.setState({...this.state, lessonCreationBar: {...this.state.lessonCreationBar, isActive: true}});
  }

  onHandleEnterPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  }

  // Customizable Area End
}
