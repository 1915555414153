//@ts-nocheck
import React from "react";
import { styled } from "@mui/material/styles";
// Customizable Area Start
import {
  InputBase,
  Box,
  Button
} from "@mui/material";
import { addImage } from "./assets";
import { trashImage } from "./assets";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
import Header from "../../../components/src/Header.web";

// Customizable Area End

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Header navigation={this.props.navigation} data-test-id="wrapper">
        <Wrapper>
          <CourseHead>
            <div className="course-wrapper">
              <div className="title">Course Title</div>
              <div className="author">Author Name</div>
            </div>
          </CourseHead>
          <CourseDescription data-test-id="description-form">
            {/* Here should be an add button */}
            {/* Here should be a wysiwyg editor */}
            
          <CustomInput
                          fullWidth
                          style={inputStyle.courseCreation}
                          data-test-id="description"
                          placeholder={configJSON.placeHolderCourseDescription}
                          value={this.state.description}
                          onChange={(event) => {
                            this.setState({ description: event.target.value });
                          }}
                        />
          </CourseDescription>
          <LessonList>
            {this.state.lessonList.length > 0 &&
            this.state.lessonList?.map((lessonItem) => 
              <LessonBlock key={lessonItem.id} data-test-id="lesson-block">
                <div style={{display: "flex", alignItems: "center", gap: 20}}>
                <Circle></Circle>
              <div className="lesson-title">{lessonItem.title}</div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 16}}>
              <button data-test-id="delete-button" onClick={() => this.deleteLesson(lessonItem.id)}><img src={trashImage} /></button>
            </div>
          </LessonBlock>
            )}

            {this.state.lessonCreationBar.isActive &&
            <LessonCreator>
            <div style={{ 
              ...inputStyle.inputBox, 
              ...(this.state.lessonList.length > 0 && { padding: "32px" })
            }}>
            <Circle></Circle>
            <CustomInput
                          fullWidth
                          style={inputStyle.lessonCreation}
                          data-test-id="lesson-title"
                          placeholder={configJSON.placeHolderLessonTitle}
                          value={this.state.lessonCreationBar.title}
                          onChange={(event) => {
                            this.setState({ lessonCreationBar: {title: event.target.value, isActive: true} });
                          }}
                          onKeyDown={(event) => this.onHandleEnterPress(event, () => this.addNewLesson(event.target.value))}
                        />
            </div>
            <div className="label-submit">{configJSON.labelPressEnterNewLesson}</div>
          </LessonCreator>
            }
            {!this.state.lessonCreationBar.isActive &&
              <AddButton data-test-id="add-lesson-button" icon={<img src={addImage}></img>} onClick={this.setCreationBarActive} />
            }
          </LessonList>
        </Wrapper>
      </Header>
      // Customizable Area End
    );
  }  
}
// Customizable Area Start

const inputStyle = {
  courseCreation: {
    fontFamily: "Crimson Text",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "32px",
    caretColor: "#000000",
    color: "#A8A29E"
  },
  lessonCreation: {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26.44px",
  },
  inputBox: {
    borderBottom: "1px solid #9D9D9D",
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: "32px 0"
  }
}

const AddButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  backgroundColor: "#1C1C1C",
  borderRadius: "50%",
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%, 50%)",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  }
})

const Circle = styled(Box)({
  width: 12,
  height: 12,
  borderRadius: "50%",
  background: "#1C1C1C",
})

const LessonCreator = styled(Box)({
  padding: "0 0 32px 0",
  "& .label-submit": {
    textAlign: "right",
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17.63px",
    marginTop: 12,
    color: "#6A6A6A",
  }
})

const LessonList = styled(Box)({
  maxWidth: "1064px",
  margin: '20px auto 0 auto',
  position: "relative"
})

const LessonBlock = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "32px",
  background: "#FFFFFF",
  borderTop: "1px solid #9D9D9D",
  borderBottom: "1px solid #9D9D9D",
  fontSize: "18px",
  fontFamily: "Heebo",
  fontWeight: 500,
  lineHeight: "26.44px",
  color: "#1C1C1C",
  "&:hover": {
    backgroundColor: "#9D9D9D"
  }
});

const CourseHead = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .course-wrapper": {
    display: "flex",
    flexDirection: 'column',
    gap: "32px",
    maxWidth: "1064px",
    margin: '0 auto',
    padding: "56px 188px 64px 188px",
  },
  "& .title": {
    fontFamily: 'Crimson Text',
    fontSize: "64px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  "& .author": {
    fontFamily: "Heebo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
})

const CourseDescription = styled(Box)({
  maxWidth: "1064px",
  margin: '0 auto',
  padding: "55px 0 0 0",
});

const CustomInput = styled(InputBase)({
  height: "32px",
  "& input": {
    height: "100%",
  }
});

const Wrapper = styled(Box)({
  height: "100%",
  width: '100%',
})

// Customizable Area End
